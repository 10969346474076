nav ul {
  margin: 0;
  padding: 0;
  height: 36px;
  max-height: 36px;
  overflow: hidden;
}

.info {
  height: 36px;
  display: flex;
}

.info-text {
  text-align: center;
  display: flex;
  align-items: center;
}

nav ul li {
  display: inline-block;
  height: 36px;
  max-height: 36px;

  color: #fff;
  position: relative;

  padding: 0 3px !important;
  margin: 0 15px 0 0 !important;
}

/* nav li:hover {
  border-bottom: 3px solid white;
  cursor: pointer;
} */
