.ant-menu-light {
  background: #1e88e5 !important;
}

.input-icon {
  color: rgba(0, 0, 0, 0.25);
}

.ant-modal-wrap {
  z-index: 1000 !important;
}
